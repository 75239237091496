<template>
    <div class="container">
        <img src="../assets/img/404.png" alt="" class="img-404">

        <div class="tips">没有权限预览</div>

        <div class="go-home" @click="toBack">
            返回首页
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        computed: {},
        mounted() {
            // 路由使用的是 history ， 所以监听 history
            if (window.history && window.history.pushState) {
                history.pushState(null, null, document.URL)
                window.addEventListener('popstate', this.toBack, false)
            }
        },
        // 离开组件时，取消监听
        destroyed() {
            window.removeEventListener('popstate', this.toBack, false);
        },
        methods: {
            toBack() {
                this.$router.replace({path: '/'})
            }
        },
        components: {},
    }
</script>

<style scoped lang="scss">
    .container {
        width: 100%;
        height: 100vh;
        min-height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #ffffff;
        .img-404 {
            width: 30%;
        }
        .tips{
            font-size: 32px;
            color: #ff6b54;
            font-weight: bold;
        }
        .go-home {
            width: 109px;
            height: 40px;
            margin-top: 30px;
            background-color: #ff6b54;
            border-radius: 20px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            color: #ffffff;
            cursor: pointer;
        }
    }
</style>